import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/distributorGroup',
    method: 'post',
    data
  })
}

export function addMembers(data) {
  return request({
    url: 'api/distributorGroup/members',
    method: 'post',
    data
  })
}

export function delMember(id) {
  return request({
    url: 'api/distributorGroup/member/' + id,
    method: 'delete'
  })
}

export function delMembers(data) {
  return request({
    url: 'api/distributorGroup/members',
    method: 'delete',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/distributorGroup/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/distributorGroup',
    method: 'put',
    data
  })
}
