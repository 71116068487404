<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="'【'+groupName+'】组成员'" @closed="cancel" :close-on-click-modal="false" width="960px">
    <el-form ref="form" v-if="groupId" label-position="right">
            <el-form-item class="gap-1x">
              <el-input v-model="query.nameOrErpId" clearable placeholder="ERP编号或名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
              <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
              <datagrid-picker v-if="!isView" reference="添加经销商" reference-type="success" url="api/distributor" :query-define="dis.queryDefine" :popper-width="800" @submit="handleDisAdd" :multiple="true" :submit="true">
                <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                  <el-input :maxlength="20" v-model="dis.queryDefine.nameOrErpId" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="ERP编号或名称搜索" style="width: 240px;">
                    <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
                  </el-input>
                </div>
                <el-table-column prop="erpId" label="ERP编号" width="120" />
                <el-table-column prop="name" label="经销商" min-width="160" />
                <el-table-column label="所在地区" :formatter="v=>{return v.districtName? v.provinceName+'/'+v.cityName + '/' + v.districtName:''}" />
              </datagrid-picker>
              <el-button class="filter-item" type="danger" icon="el-icon-delete" @click="handleBatchDelete" :disabled="multipleSelection.length==0" v-if="!isView">批量移除</el-button>

              <el-button type="info" @click="upload" class="filter-item" icon="el-icon-upload2" v-if="!isView">导入</el-button>
              <el-button class="filter-item" type="info" icon="el-icon-download" :loading="downloadLoading"  @click="toDownload" v-if="!isView">导出</el-button>
              <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;" empty-text="还还没有经销商成员" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"/>
                <el-table-column label="ERP编码" prop="erpId" width="150" />
                <el-table-column label="经销商" prop="name" min-width="160" show-overflow-tooltip />
                <el-table-column label="经销商企业类型" prop="enterpriseTypeName" min-width="100" show-overflow-tooltip />
                <el-table-column label="所在地区" min-width="160" show-overflow-tooltip  :formatter="v=>{return v.districtName? v.provinceName+'/'+v.cityName + '/' + v.districtName:''}" />
                <el-table-column fixed="right" width="60" v-if="!isView">
                    <div class="row-commands" slot-scope="scope">
                    <!-- <el-button size="mini" type="text" icon="el-icon-delete" @click="remove(scope.row,2)" /> -->
                    <el-popover :ref="scope.row.id" placement="top" width="180">
                      <p>确定移除此经销商吗？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                        <el-button :loading="delLoading" type="primary" size="mini" @click="remove(scope.row.id)">确定</el-button>
                      </div>
                      <el-button slot="reference" class="danger" type="text" size="mini">移除</el-button>
                    </el-popover>
                    </div>
                </el-table-column>
              </el-table>
              <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
            </el-form-item>
            <div class="dialog-footer h c">
              <div class="flex" />
              <el-button type="text" @click="dialog=false">取消</el-button>
            </div>
    </el-form>
    <importForm ref = "importForm"/>
  </el-dialog>
</template>
<script>
import initData from "@/mixins/initData";
import { addMembers,delMember,delMembers } from "@/api/distributorGroup";
import { download } from '@/api/data';
import { downloadFile } from '@/utils/index';
import importForm from "./member-import"
export default {
  components: { importForm },
  props: {
    isView: {
      type: Boolean,
      required: true
    }
  },
  mixins: [initData],
  data() {
    return {
      downloadLoading:false,
      dialog: false,
      loading:false,
      delLoading:false,
      groupId:null,
      groupName:'',
      members:[],
      dis: {
        queryDefine: {}
      },
      query:{
        nameOrErpId:null,
      },
      multipleSelection:[]
    };
  },
  methods: {
    cancel(){
      this.query.nameOrErpId = "";
    },
    beforeInit() {
      if(!this.groupId) return false;  
      this.url = "api/distributorGroup/members/"+this.groupId;
      const sort = "id,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },  
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    handleBatchDelete(){
      //confirm
       this.$confirm("您确定要从当前经销商组移除所选成员吗？", "操作确认", {
          type: "warning"
        }).then(_ => {
          this.batchDel();
        });      
    },
    upload(){
      this.$refs.importForm && this.$refs.importForm.resetForm(this.groupId);
    },
    batchDel(){
      let ids = [];
      this.multipleSelection.forEach(item=>{
        ids.push(item.id);
      });
      if(ids.length==0) return;
      this.delLoading = true;
      delMembers(ids).then(_=>{
        this.delLoading = false;        
        this.init();
        this.$parent.init();
      }).catch(err => {
          this.delLoading = false;
        });
    },
    handleDisAdd(selectItems) {
      let items=[];
      selectItems.forEach(item => {
        items.push({
          groupId:this.groupId,
          distributorId:item.id,
          distributorEntId:item.enterpriseId,
        });
      });  
      addMembers(items).then(_=>{
        this.init();
        this.$parent.init();
      });
    },  
    remove(id) {
      this.delLoading = true;
      delMember(id).then(_=>{
        this.delLoading = false;
        this.$refs[id].doClose();
        this.init();
        this.$parent.init();
      }).catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    refresh(groupId,groupName){
        this.groupId = groupId;
        this.groupName = groupName;
        this.dialog = true;
        this.init();
    },
    toDownload(){
      if(this.data && this.data.length===0){
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download('api/distributorGroup/members/download/'+this.groupId, this.params).then(result => {
        downloadFile(result, '【'+this.groupName+'】组成员', 'xlsx')
        this.downloadLoading = false;
      }).catch((err) => {
        this.$message.error(err.message || "操作失败，请稍候再试。");
        this.downloadLoading = false;
      })
    }
  }
};
</script>
