<template>
  <el-card header="经销商组列表" shadow="never">
    <div class="head-container">
      <el-input v-model="query.name" clearable placeholder="输入经销商组搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eMemberForm ref="memberForm" :is-view="true" />
    <el-table v-loading="loading" row-key="id" :data="data" size="small" highlight-current-row @current-change="handleDistributorGroupChange" style="width: 100%;">
      <el-table-column prop="name" label="经销商组" min-width="150" />
      <el-table-column prop="disCount" label="经销商数量" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="viewDis(scope.row.id,scope.row)">{{scope.row.disCount}}</el-button>
        </template>
      </el-table-column>
      <el-table-column width="60px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="viewDis(scope.row.id,scope.row)">成员</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </el-card>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eMemberForm from "../group/members";

export default {
  components: { eMemberForm },
  mixins: [initData],
  props: {
    value: String | Number,
  },
  data() {
    return {
      query: {
        name: null,
      },
    };
  },

  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributorGroup";
      this.params = Object.assign({ sort: "createAt,desc" }, this.query);
      this.handleDistributorGroupChange(null);
      return true;
    },
    handleDistributorGroupChange(d) {
      this.$emit("input", d);
    },
    viewDis(groupId, val) {
      this.$refs.memberForm.refresh(groupId, val.name);
    },
  },
};
</script>